import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import avatarLogo from '../../images/travelpix-logo-black.svg';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    alert: {
        marginTop: theme.spacing(4),
        textAlign: 'center',
        paddingLeft: 16,
        paddingRight: 16
    }
}));

const MobileNotSupport = () => {
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img src={avatarLogo} alt="Logo" />
                <Typography variant="h5" className={classes.alert}>
                    This page doesn't support mobile browsers.<br></br>Please use your desktop browser to access.
                </Typography>
            </div>
        </Container>
    )
}

export default MobileNotSupport;